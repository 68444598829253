import React from "react"
import NavbarThree from "../components/App/NavbarThree"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"

import MainBanner from "../components/SolutionRecruitments/Sa1mainBanner"
import HowItWork from "../components/SolutionRecruitments/Sa2howItWork"
import CaseStudySlider from "../components/SolutionRecruitments/Sa5caseStudySlider"
import AlternateBanner from "../components/SolutionRecruitments/Sa3alternateBanner"
import BlogPost from "../components/SolutionRecruitments/Sa6blogPost"
import Form from "../components/SolutionRecruitments/Sa4form"

const recruitment = ({location}) => {
  return (
    <Layout path={location.pathname}>
      <NavbarThree />

      <MainBanner />
      <HowItWork />
      <AlternateBanner />
      <Form />
      <CaseStudySlider />
      <BlogPost />

      <Footer />
    </Layout>
  )
}

export default recruitment;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;